import { useState, useEffect } from 'react'
import { Card, Title, Button, Flex, Text, Divider, Callout } from '@tremor/react';
import DevicesTable from './table';
import Nav from '../../components/nav';
import * as devicesAPI from '../../services/device';
import AddDevice from './add';
import { 
  CpuChipIcon,  
  PlusCircleIcon
} from '@heroicons/react/24/outline';

const EmptyDevice = function({ open }) {
  return (
    <>
        <div className="mt-4 flex h-52 items-center justify-center rounded-tremor-small border border-dashed border-tremor-border p-4 dark:border-dark-tremor-border">
          <div className="text-center">
            <CpuChipIcon
              className="mx-auto h-7 w-7 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
              aria-hidden={true}
            />
            <p className="mt-2 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              No Devices available
            </p>
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
              Get started by connecting a device
            </p>
            <Button
              icon={PlusCircleIcon}
              onClick={open}
              type="button"
              className="mt-6 inline-flex items-center gap-1.5 whitespace-nowrap rounded-tremor-small bg-tremor-brand px-3 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
            >
              Add Device
            </Button>
          </div>
        </div>
    </>
  );
}


export default function Devices() {
  let [isOpen, setIsOpen] = useState(false)
  let [devices, setDevices] = useState([])
  let [error, setError] = useState(null)

  useEffect(() => {
    const getDevices = async () => {
      try {
        const data = await devicesAPI.fetchDevices();
        setDevices(data);
      } catch (err) {
        console.error("Error fetching devices:", err);
      }
    };

    getDevices();
  }, []);

  const initDeviceForm = () => {
    setIsOpen(true);
  }

  const onAddDevice = async (device) => {
    try {
      const newDevice = await devicesAPI.addDevice(device);
      setDevices([...devices, newDevice]); // Add the new device to the list
      setIsOpen(false); // Close the form/modal
    } catch (err) {
      const errMsg = err.message || "Failed to add device";
      setError(errMsg);
      setTimeout(() => { setError(null) }, 3000);
      setIsOpen(false); // Close the form/modal
    }
  }

  return (
    <div>
      <Nav />
      <main className="p-4 md:p-10 mx-auto max-w-7xl">
        <Flex>
          <div>
            <Title>Devices</Title>
            <Text>
              Devices available to connect to the network.
            </Text>
          </div>
          <div>
            <Button variant="primary" size='xs' onClick={initDeviceForm}>Add Device</Button>
          </div>
        </Flex>
        <Divider />
        <Card className="mt-6">
          { error && <Callout title="Error: failed to add device." color="red" className='mb-2'>{error}</Callout> }
          {devices.length > 0  ? ( <DevicesTable devices={devices} /> ) : (<EmptyDevice open={setIsOpen} />)}
        </Card>
        {isOpen && ( <AddDevice onCancel={setIsOpen} onAction={onAddDevice} />
  
        )}
    </main>
    </div>
  );
}