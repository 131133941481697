import {Text, Flex, Metric } from "@tremor/react";
import { BeakerIcon } from "@heroicons/react/24/outline";

export default function Value({ highlight, capability }) {

  const formatNumberValue = (value) => {
    if (isNaN(value)) return value;
    return value.toFixed(2);
  }

  return (
    <>
      <Flex justifyContent="center" alignItems="center" className="mt-2">
        {BeakerIcon && <BeakerIcon className="w-6 h-6 mr-2" />}
        <Metric className="text-4xl">{formatNumberValue(capability.value)}</Metric>
        <Text className="text-sm ml-2 mt-3">{capability.unit}</Text>
      </Flex>
    </>
  )
}