import { Flex } from "@tremor/react";
import Toggle from "../../components/Toggle";
import Slider from "./Slider"


export default function Actuator({ capability, handleToggle }) {

  return (
    <div>
      <Flex justifyContent="center" alignItems="center">
        { capability.type === 'digital_actuator' ? (<Toggle value={capability.value} onToggle={handleToggle} />): (
          <Slider min={0} max={100} step={1} initialValue={capability.value} onValueChange={handleToggle} />
        )}
      </Flex>
    </div>
  )
}