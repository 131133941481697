import React, { useEffect, useState } from 'react';
import { Card, Title, 
  Flex, Text,
  Button ,
  Select,
  SelectItem,
  MultiSelect,
  MultiSelectItem,
  Badge,
} from '@tremor/react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Spinner from '../../../components/spinner';
import LogTable from './logtable';
import * as devicesAPI from '../../../services/device';
import Pagination from './pagination';
import moment from 'moment';
import DateRange from '../../../components/DateRange';

export default function LogPanel({ deviceId, capabilities }) {
  const [events, setEvents] = useState({ readings: []});
  const [pakcetCount, setPacketCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [timeFrame, setTimeFrame] = useState('-1');
  const [loading, setLoading] = useState(false);

  // get filter values
  const [channels, setChannels] = useState([]);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);

  // set filter values
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);

  const getStartDate = () => {
    let startDate;
    switch (timeFrame) {
      case '15m':
        startDate = moment().subtract(15, 'minutes').toISOString();
        break;
      case '1h':
        startDate = moment().subtract(1, 'hours').toISOString();
        break;
      case '30m':
        startDate = moment().subtract(30, 'minutes').toISOString();
        break;
      case '24h':
        startDate = moment().subtract(24, 'hours').toISOString();
        break;
      case '3d':
        startDate = moment().subtract(3, 'days').toISOString();
        break;
      case '1w':
        startDate = moment().subtract(1, 'weeks').toISOString();
        break;
      case '1mo':
        startDate = moment().subtract(1, 'months').toISOString();
        break;
      default:
        startDate = moment().subtract(15, 'minutes').toISOString();
    }

    return startDate;
  }

  const getReadings = async () => {
    try {
      setLoading(true);
      const filters = { channels: selectedChannels, types: selectedTypes, units: selectedUnits };
      let data;
      if (timeFrame === '-1') {
        data = await devicesAPI.fetchReadings(deviceId, currentPage, 10, null, null, filters);
      } else {
        const endDate = moment().toISOString();
        const startDate = getStartDate();
        data = await devicesAPI.fetchReadings(deviceId, currentPage, 10, startDate, endDate, filters);
      }
      setEvents(data);
      setTotalPages(data.totalPages);
      setPacketCount(data.count);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching device:", err);
    }
  }

  useEffect(() => {
    if (deviceId === undefined) {
      return;
    }
    
    getReadings();

  }, [deviceId, currentPage, timeFrame]); 

  useEffect(() => {
    // map capabilities to filter values
    const filteredCapabilities = capabilities.filter((cap) => {
      return cap.type !== 'digital_actuator' && cap.type !== 'analog_actuator';
    });

    const channels = filteredCapabilities.map((cap) => cap.channel);
    const types = filteredCapabilities.map((cap) => cap.type);
    const units = filteredCapabilities.map((cap) => cap.unit);

    setChannels([...new Set(channels)]);
    setTypes([...new Set(types)]);
    setUnits([...new Set(units)]);
  }, [capabilities]);

  const onChange = (page) => {
    setCurrentPage(page);
    getReadings();
  }

  const onDateSelector = async (value) => {
    setTimeFrame(value);
    await getReadings();
  }

  const onRefresh = () => {
    getReadings();
  }

  useEffect(() => {
    getReadings();
  }, [selectedChannels]);

  useEffect(() => {
    getReadings();
  }, [selectedTypes]);

  useEffect(() => {
    getReadings();
  }, [selectedUnits]);

  const onChannelChange = (value) => {
    // set selected channels currently with react state
    setSelectedChannels([...value]);
  }

  return (
    <div>
      <Card className="mt-5">
        <Flex>
          <div>
            <Flex justifyContent="start" className="space-x-2">
              <Title>Logs</Title>
              <Badge size="xs">{pakcetCount} Entries</Badge>
            </Flex>
          </div>
          <div>
            <Flex justifyContent="end" className="space-x-2">
           
            </Flex>
          </div>
        </Flex>
        
        {/* <Histogram /> */}
        <hr className="mt-2" />
        <div>
          <Flex className="space-x-2 mt-2">
            <Text className='w-32'>Filter:</Text>
          <MultiSelect placeholder="Channel" onValueChange={onChannelChange}>
            { channels.map((channel) => (
              <MultiSelectItem key={channel} value={channel}>{channel}</MultiSelectItem>
            ))}
          </MultiSelect>
          <MultiSelect placeholder="Type" onValueChange={setSelectedTypes}>
            { types.map((type) => (
              <MultiSelectItem key={type} value={type}>{type}</MultiSelectItem>
            ))}
          </MultiSelect>
          <MultiSelect placeholder="Unit" onValueChange={setSelectedUnits}>
            { units.map((unit) => (
              <MultiSelectItem key={unit} value={unit}>{unit}</MultiSelectItem>
            ))}
          </MultiSelect>
            <DateRange onDateSelector={onDateSelector} />
            <Button onClick={onRefresh} icon={ArrowPathIcon}>
              Refresh
            </Button>
          </Flex>
        </div>
        { loading ? (<Spinner />) : 
          <div className="logtable">
            <LogTable logs={events} />
            { events.readings.length > 1 && (
              <div className="mt-4">
                <Pagination 
                  currentPage={currentPage} 
                  totalPages={totalPages} 
                  onChange={onChange} 
                />
              </div>
            )}
          </div>
         }
      </Card>
    </div>
  )
}