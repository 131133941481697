import React, { useEffect, useState } from "react";
import { Text, Flex, Card, Button } from "@tremor/react";
import { PlusCircleIcon, AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { toFriendlyDate, toFriendlyTime } from "../../services/utils";
import * as deviceAPI from "../../services/device";
import Chart from "./Chart";
import Value from "./Value";
import Gauge from "./Gauge";
import Actuator from "./Actuator";

const ANALOG_ACTUATOR = 'analog_actuator';
const DIGITAL_ACTUATOR = 'digital_actuator';

const chartDateFilters = [
  { label: 'D', value: '1d', alt: 'Last 24 Hours' },
  { label: 'W', value: '1w', alt: 'Last 7 Days' },
  { label: 'M', value: '1m', alt: 'Last 30 Days' },
  { label: '6M', value: '6m', alt: 'Last 6 Months' },
  { label: 'YTD', value: 'ytd', alt: 'Year to Date' },
];

export default function Widget({ deviceId, capability, onAddCapability, onEditCapability, onActuatorAction }) {
  const [data, setData] = useState([]);
  const [isActuator, setIsActuator] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false); // New state to track initialization
  const [widgetType, setWidgetType] = useState('metric');
  const [selectedFilter, setSelectedFilter] = useState('1d');
  

  function transformData(data) {
    // format the data to be used by the chart
    if (data.length === 0) return [];
    return data.map(item => ({
      date: toFriendlyDate(item.timestamp),
      Measurement: item.value
    }));
  }

    // Effect to highlight the metric value when it changes
    useEffect(() => {
      if (!isInitialized) return; // Skip the first render
      setHighlight(true);
      const timeout = setTimeout(() => setHighlight(false), 500); // Reset after 500ms

      if (data.length !== 0) {
        // lookup the latest value and timestamp in the data array
        const latestValue = data[data.length - 1];

        if (latestValue.Measurement !== capability.value) {
          const newMetric = {date: toFriendlyTime(Date.now()), Measurement: capability.value};
          setData([...data, newMetric]);
        }
      }
      
      return () => clearTimeout(timeout);
     
    }, [capability.value, isInitialized]);
  

  useEffect(() => {
    const fetchData = async () => {
      // todo: filter by last 24 hours only
      if (!capability.channel) return;
      const result = await deviceAPI.fetchMetric(deviceId, capability.channel, '');
      const chartData = transformData(result);
      if (capability.widget && capability.widget.type) {
        setWidgetType(capability.widget.type);
      } else if (capability.type === 'digital_actuator') {
        setWidgetType('switch');
      } else if (capability.type === ANALOG_ACTUATOR) {
        setWidgetType('slider');
      } else {
        setWidgetType('metric');
      }
      
      setData(chartData);
      setIsInitialized(true);
    };

    if (capability.type === 'digital_actuator' || capability.type === ANALOG_ACTUATOR) {
      setIsActuator(true);
    }

    if (!capability.new || !capability._id) {
      fetchData();
    }

  }, [capability._id, capability.channel, capability.new, capability.type, deviceId, capability.widget]);

  const handleToggle = (value) => {
    if (capability.type === 'digital_actuator') {
      if (value) {
        capability.value = 1;
      } else {
        capability.value = 0;
      }
    } else if (capability.type === ANALOG_ACTUATOR) {
      capability.value = value;
    }

    onActuatorAction(capability);
  }

  const onDateFilter = async (e) => {
    setSelectedFilter(e.value);
    const options = 'date_filter=' + e.value;
    const result = await deviceAPI.fetchMetric(deviceId, capability.channel, options);
    const chartData = transformData(result);
    setData(chartData);
  }

  return (
    <Card decoration="top" decorationColor={capability.new ? 'green':'indigo'} className="h-full">
      <Flex alignItems="start">
        <Text className="widget-name z-widget-handle hover:cursor-pointer hover:cursor-move">{capability.name || capability.type}</Text>
        {!capability.new && (
          <Button 
            variant="light" 
            onClick={() => onEditCapability(capability)} 
            size="sm" 
            className="ml-2 border-1"
            icon={AdjustmentsVerticalIcon}>
          </Button>
        )}
        {capability.new && (<Button variant="light" onClick={() => onAddCapability(capability)} size="sm" className="ml-2 border-1" icon={PlusCircleIcon}>Add</Button>)}
      </Flex>
      { (widgetType === 'areachart' || widgetType === 'barchart') ? (
        <div className="mt-2 bg-gray-100">
          <Text className="text-xs py-1">
            { chartDateFilters.map((filter, index) => (
              <React.Fragment key={filter.value}>
                <Button 
                  variant="light"
                  tooltip={filter.alt}
                  onClick={() => onDateFilter(filter)} 
                  className={`text-xs cursor-pointer px-2 ${selectedFilter === filter.value ? 'text-gray-600 hover:text-blue-600':  'text-blue-600 font-semibold'}`}>
                  {filter.label}
                </Button>
              {index < chartDateFilters.length - 1 && <span className="text-gray-400">|</span>}
            </React.Fragment>
            ))}
          </Text>
        </div>
      ): ''}
      { widgetType === 'metric' ? (
       <Value capability={capability} />
       ) : (widgetType === 'areachart' || widgetType === 'barchart') ? (
        <Chart data={data} capability={capability} />
       ) : (widgetType === 'gauge') ? (
        <Gauge capability={capability} />
       ) : (widgetType === 'switch' || widgetType === 'slider') ? (
         <Actuator capability={capability} handleToggle={handleToggle} />
       ): (
          <Text>Unsupported widget type</Text>
         )}
      <div className="mt-5 mb-5 flex justify-left">
        {/* <Text>Last 24 hours</Text> */}
      </div>
    </Card>
  )
}