import React from 'react';
import { Select, SelectItem } from '@tremor/react';


const quickRanges = [
  { label: 'Last 15 minutes', value: '15m' },
  { label: 'Last 30 minutes', value: '30m' },
  { label: 'Last hour', value: '1h' },
  { label: 'Last 24 hours', value: '24h' },
  { label: 'Last 3 days', value: '3d' },
  { label: 'Last week', value: '1w' },
  { label: 'Last month', value: '1mo' },
  { label: 'All time', value: '-1' },
];

export default function DateRange({ onDateSelector, placeholder="Select time frame" }) {
  return (
    <>
      <Select onValueChange={onDateSelector} 
        placeholder={placeholder}>
        { quickRanges.map((range) => (
          <SelectItem key={range.value} value={range.value} defaultValue={-1}>
            {range.label}
          </SelectItem>
        ))}
      </Select>
    </>
  )
}