import { AreaChart, BarChart, Text } from "@tremor/react";
import { ResponsiveContainer } from "recharts";

export default function Chart({ data, capability }) {
  return (
      <ResponsiveContainer height={'70%'}>
        { /* chart types */}
        { capability.widget.type === 'areachart' ? (
        <AreaChart
          className="mt-6"
          data={data}
          index="date"
          valueFormatter={value => `${value.toFixed(2)} ${capability.unit}`}
          categories={['Measurement']}
          colors={["blue"]}
          noDataText="No data for the last 24 hours"
          showXAxis={true}
          showGridLines={false}
          startEndOnly={true}
          showYAxis={false}
          showLegend={false}
          showAnimation={true}
        />) : capability.widget.type === 'barchart' ? ( <BarChart
          className="barhop mt-6"
          data={data}
          index="date"
          valueFormatter={value => `${value.toFixed(2)} ${capability.unit}`}
          categories={['Measurement']}
          colors={["blue"]}
          noDataText="No data for the last 24 hours"
          showXAxis={true}
          showGridLines={false}
          startEndOnly={true}
          showYAxis={false}
          showLegend={false}
          showAnimation={true}
        />) : (
          <div /> // Fallback to an empty div or another chart if none matches
        )}
    </ResponsiveContainer>
  )
}