import React, { useEffect, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import Widget from '../components/dashboard/Widget';
import * as devicesAPI from '../services/device';

const ReactGridLayout = WidthProvider(RGL);

const WidgetLimits = {
  metric: { minH: 1, minW: 2, h: 2, w: 4 },
  areachart: { minH: 2, minW: 4, h: 2, w: 4 },
  barchart: { minH: 2, minW: 4, h: 2, w: 4 },
  gauge: { minH: 2, minW: 2, h: 2, w: 2 },
  switch: { minH: 1, minW: 2, h: 1, w: 2 },
  slider: { minH: 2, minW: 4, h: 1, w: 2 },
}

// Helper function to generate the initial layout
const generateInitialLayout = (capabilities) => {
  // Sort capabilities so that new ones appear first
  const sortedCapabilities = capabilities.sort((a, b) => (b.new ? 1 : 0) - (a.new ? 1 : 0));
  
  const positions = {}; // Keep track of occupied positions

  return sortedCapabilities.map((capability, index) => {
    const widget = capability.widget || {
      type: 'metric',
    };
    let x, y;

    if (capability.new) {
      // Calculate the next available x, y position
      x = 0;
      y = 0;
      while (positions[`${x},${y}`]) {
        x += 2;
        if (x >= 12) { // Move to the next row if x exceeds grid width
          x = 0;
          y++;
        }
      }
    } else {
      // Use existing positions for non-new widgets
      x = widget.x ?? (index % 3) * 4;
      y = widget.y ?? Math.floor(index / 3);
    }

    // Mark the position as occupied
    positions[`${x},${y}`] = true;

    return {
      x,
      y,
      w: widget.w ?? 4,
      h: widget.h ?? 2,
      i: capability._id,
      minH: WidgetLimits[widget.type ?? 'metric'].minH,
      minW: WidgetLimits[widget.type ?? 'metric'].minW,
    };
  });
}

export default function DashboardGrid({ capabilities, deviceId, onAddCapability, onEditCapability, onActuatorAction }) {
  const [layout, setLayout] = useState(generateInitialLayout(capabilities));
  const [isInitialized, setIsInitialized] = useState(false); // New state to track initialization

  // Function to handle layout changes
  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
    updateLayout(newLayout); // Save the new layout to the database
  };

  const updateLayout = async (layout) => {
    if (!isInitialized) return; // Skip the first render
    try {
      // append capabilities widget.type to the layout
      const newLayout = layout.map((item, index) => {
        const capability = capabilities.find(c => c._id === item.i);
        return {
          ...item,
          type: (capability.widget && capability.widget.type) ? capability.widget.type : 'metric',
        };
      });
      await devicesAPI.patchCapabilities(deviceId, newLayout); 
    } catch (error) {
      console.error('Failed to save layout', error);
    }
  };

  useEffect(() => {
    setIsInitialized(true);
  }, [layout]);

  useEffect(() => {
    setLayout(generateInitialLayout(capabilities));
  }, [capabilities]);


  return(
    <ReactGridLayout
      layout={layout}
      cols={12}
      isResizable={true}
      isDraggable={true}
      compactType={'vertical'}
      rowHeight={125}
      isBounded={true}
      margin={[5, 5]}
      onLayoutChange={handleLayoutChange}
      draggableHandle='.z-widget-handle'
    >
      {capabilities.map((capability, index) => (
        <div key={capability._id}>
           <Widget 
            key={capability._id} 
            deviceId={deviceId} 
            capability={capability}
            onAddCapability={onAddCapability}
            onEditCapability={onEditCapability}
            onActuatorAction={onActuatorAction}
            />
        </div>
      ))}
    </ReactGridLayout>
  )
}