import { useState } from 'react'
import { Card, Title, Button, Divider, TextInput } from '@tremor/react';
import { Link, useNavigate } from "react-router-dom";
import Alert from '../../components/alert';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function RegisterPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('')
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    try {
      setLoading(true);
      const response = await fetch('/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: username, password, name })
      });

      const data = await response.json();
      setLoading(false);
      if (response.ok) {
        navigate('/login?regok=true');
      } else {
        setError(data.error || 'Failed to register');
        setTimeout(() => {
          setError(null);
        }, 3000);
      }

    } catch (err) {
      setLoading(false);
      setError('Something went wrong. Please try again later.');
    }
  }

  return (
      <main className="p-4 md:p-10 mx-auto max-w-7xl">
        <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0'>
          <a href="/" className="flex items-center mb-6 text-2xl font-semibold">
            <img className="w-12 h-12 mr-2" src="https://zafron.dev/img/zafron.png" alt="logo" />
            Zafron    
            </a>
          <Card className="max-w-xs">
            <Title>Sign Up</Title>
            <Divider />
            { error && <Alert title="Register Error" message={'Email or password is invalid.'}>{error}</Alert> }
            <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name:
                </label>
                <TextInput id="name" type="text" placeholder='Azucar' value={name} 
                  onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  E-mail Address:
                </label>
                <TextInput id="email" type="email" placeholder='E-mail adress' value={username} 
                  onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password:
                </label>
                <TextInput id="password" type="password" placeholder='' value={password} 
                  onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirm-password">
                  Confirm Password:
                </label>
                <TextInput id="confirm-password" type="password" placeholder='' />
              </div>
              <div className="mb-4">
                <Button disabled={loading} variant="primary" size='xs' className='w-full' loading={loading}>Register</Button>
              </div>
            </form>
            <Divider></Divider>
            <div className="mb-4">
              <Button icon={ArrowLeftIcon} iconPosition="left" variant="light" size='xs' onClick={() => navigate('/login')}>Login</Button>
            </div>
          </Card>
        </div>
        
    </main>
  );
}