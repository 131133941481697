import React, { useEffect, useState } from "react";
import { Flex, ProgressCircle, Text } from "@tremor/react";

export default function Gauge({ highlight, capability }) {
  const [value, setValue] = useState(capability.value);

  // adjust value to be within 0 and 100 percent ratio
  useEffect(() => {
    const newValue = capability.value > 100 ? 100 : capability.value;
    setValue(newValue);
  }, [capability.value]);

  return (
    <div className="flex flex-col items-center justify-center mt-2 h-full">
      <ProgressCircle value={value} radius={64} size="lg">
        <span className="text-md font-medium text-slate-700">{value.toFixed(2)}</span>
        <span className="text-md font-medium text-slate-700 ml-1">{capability.unit}</span>
      </ProgressCircle>
      <Text className="mt-2">{capability.type}</Text>
    </div>
  )
}