import { AreaChart, Text, Flex, Card, Metric, Button, BarChart } from "@tremor/react";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import * as deviceAPI from "../../services/device";
import { useState, useEffect } from "react";
import { toFriendlyTime } from "../../services/utils";
import Toggle from "../../components/Toggle";
import { ResponsiveContainer } from "recharts";
import classNames from 'classnames';

// format to 2 decimal places
const formatNumberValue = (value) => {
  return value.toFixed(2);
}

export default function MetricCard({ deviceId, capability, onAddCapability, onEditCapability, onSwitchToggle }) {
  const [data, setData] = useState([]);
  const [isActuator, setIsActuator] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false); // New state to track initialization

  function transformData(data) {
    return data.map(item => ({
      date: toFriendlyTime(item.timestamp),
      Measurement: item.value
    }));
  }

    // Effect to highlight the metric value when it changes
    useEffect(() => {
      if (!isInitialized) return; // Skip the first render
      setHighlight(true);
      const timeout = setTimeout(() => setHighlight(false), 500); // Reset after 500ms

      // lookup the latest value and timestamp in the data array
      const latestValue = data[data.length - 1];

      if (latestValue.Measurement !== capability.value) {
        const newMetric = {date: toFriendlyTime(Date.now()), Measurement: capability.value};
        setData([...data, newMetric]);
      }
       return () => clearTimeout(timeout);
     
    }, [capability.value, isInitialized]);
  

  useEffect(() => {
    const fetchData = async () => {
      // todo: filter by last 24 hours only
      if (!capability.channel) return;
      const result = await deviceAPI.fetchMetric(deviceId, capability.channel);
      if (result.length === 0) return;
      const chartData = transformData(result);
      setData(chartData);
      setIsInitialized(true);
    };

    if (capability.type === 'digital_actuator' || capability.type === 'analog_actuator') {
      setIsActuator(true);
    }

    if (!capability.new || !capability._id) {
      fetchData();
    }

  }, [capability._id, capability.channel, capability.new, capability.type, deviceId]);

  const handleToggle = (value) => {
    if (value) {
      capability.value = 1;
    } else {
      capability.value = 0;
    }

    onSwitchToggle(capability);
  }

  return (
    <Card decoration="top" decorationColor={capability.new ? 'green':'indigo'} className="h-full">
      <Flex alignItems="start">
        <Text>{capability.name || capability.type}</Text>
        {!capability.new && (
          <Button 
            variant="light" 
            onClick={() => onEditCapability(capability)} 
            size="sm" 
            className="ml-2 border-1" 
            icon={PencilSquareIcon}>
          </Button>
        )}
        {capability.new && (<Button variant="light" onClick={() => onAddCapability(capability)} size="sm" className="ml-2 border-1" icon={PlusCircleIcon}>Add</Button>)}
      </Flex>
      <Flex justifyContent="between" alignItems="baseline">
        <div>
          <Flex className="space-x-3 truncate" justifyContent="start" alignItems="baseline">
            <Metric className={classNames("break-words", {"highlight": highlight})}>{formatNumberValue(capability.value)}</Metric>
            <Text>{capability.unit}</Text> 
          </Flex>
        </div>
        <Text>{`Ch. ${capability.channel}`}</Text>
      </Flex>
      { !capability.new && !isActuator && (
        <ResponsiveContainer height={'50%'}>
          { (capability.widget.type === 'areachart' || (capability.widget && !capability.widget.type) || !capability.widget) && (
            <AreaChart
              className="areachart-tremo mt-6"
              data={data}
              index="date"
              valueFormatter={value => `${value.toFixed(2)} ${capability.unit}`}
              categories={['Measurement']}
              colors={["blue"]}
              showXAxis={true}
              showGridLines={false}
              startEndOnly={true}
              showYAxis={false}
              showLegend={false}
              showAnimation={true}
            />
          )}
          {/* { capability.widget && capability.widget.type === 'barchart' && (
            <BarChart
              className="barchart-tremo mt-6"
              data={data}
              index="date"
              categories={['Measurement']}
              colors={['blue']}
              valueFormatter={value => `${value.toFixed(2)} ${capability.unit}`}
              yAxisWidth={48}
          />
        )} */}
        </ResponsiveContainer> )}
        
      { isActuator && 
        <div>
          <Flex justifyContent="center" alignItems="center">
            <Toggle value={capability.value} onToggle={handleToggle} />
          </Flex>
        </div>
        }
    </Card>
  );
}